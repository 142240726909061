import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import iCalendarPlugin from '@fullcalendar/icalendar'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import { isMobile } from 'react-device-detect'

export function View() {
  const parsedQueryString = queryString.parse(useLocation().search)
  const calendarUrl = parsedQueryString.calendar

  return (
    <FullCalendar
      height="100%"
      plugins={[dayGridPlugin, listPlugin, iCalendarPlugin]}
      headerToolbar={{
        left: 'prev,next',
        center: 'title',
        right: 'dayGridMonth,listAllFromToday',
      }}
      initialView={isMobile ? 'listAllFromToday' : 'dayGridMonth'}
      events={{
        url: calendarUrl as string,
        format: 'ics',
      }}
      views={{
        listAllFromToday: {
          type: 'list',
          visibleRange: {
            start: new Date(),
            end: '2030-01-01',
          },
          buttonText: 'schedule',
          listDaySideFormat: {
            weekday: 'long',
          },
          titleFormat: () => {
            return 'Schedule'
          },
          eventDidMount: (info) => {
            if (info.event.extendedProps.description) {
              const descriptionElement = document.createElement('a')
              descriptionElement.appendChild(
                document.createTextNode(info.event.extendedProps.description)
              )
              descriptionElement.setAttribute('style', 'font-size: 0.8rem; font-style: italic;')
              const titleElement = info.el.getElementsByClassName('fc-list-event-title')[0]
              titleElement.appendChild(document.createElement('br'))
              titleElement.appendChild(descriptionElement)
            }
          },
        },
      }}
    />
  )
}
